/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
// DEPENDENCIES
import React, { useState } from 'react';
import { connect } from 'react-redux';
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import Banner from '../../../components/Banner';
import Overlay from '../../../components/Overlay';
import ContactForm from '../../../components/ContactForm';
import Image from '../../../components/Image';
// import ContentSection from '../../../components/ContentSection';
// ASSETS - CONTACT PAGE
import BannerImage from '../../../assets/img/banner-image_default.png';
import BackgroundDecorationImage from '../../../assets/img/background-decoration_dark-light-waves.svg';
// SERVICES AND HELPERS
import * as auth from '../../../redux/authRedux';
import * as alertToast from '../../../redux/alertToastRedux';
import * as emailService from '../../../services/management/emailService';

const ContactPage = (props) => {
  const { showAlert, history, cmsInfo } = props;
  const [isLoading, setIsLoading] = useState(false);
  // const getDirectionsLink = 'https://www.google.com/maps/place/142+Del+Norte+Ave,+Denham+Springs,+LA+70726/@30.4706975,-90.9551617,17z/data=!3m1!4b1!4m6!3m5!1s0x8626bef0ef5cd50d:0x915fc5c888dc7ba6!8m2!3d30.4706975!4d-90.9551617!16s%2Fg%2F11c28v5b_f?entry=ttu';
  const formConfig = [
    { name: 'firstName', label: 'First Name', required: true, placeholder: 'e.g. John', max: 100 },
    { name: 'lastName', label: 'Last Name', required: true, placeholder: 'e.g. Smith', max: 100 },
    { name: 'email', label: 'Email Address', required: true, placeholder: 'e.g. johnsmith@example.com', max: 100, email: true },
    { name: 'phoneNumber', label: 'Phone Number', placeholder: 'e.g. (555) 555-5555', max: 50 },
    { name: 'message', label: 'Message', required: true, placeholder: 'Provide your message here...', textarea: true, cols: 12 },
  ];

  const submitForm = async (values) => {
    setIsLoading(true);
    await emailService.sendEmail(values).then(() => {
      showAlert({ text: 'Success! Your message has been received.', state: 'success' });
      history.push('/contact-jayroe');
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isDynamicBanner
        isTitleUppercase
        title="Contact"
        imageSource={BannerImage}
      />

      {/* <CustomBlock className="content-container--padded"> */}
      <CustomBlock className="main-content">
        {/* <ContentSection
          hasRoundCorners
          isFullWidth
          title="Connect With Us"
          description="Your dream property is just a message away. Contact us to make it a reality."
          imageSource={IntroSectionImage}
          primaryActionText="Get Directions"
          primaryActionOnClick={() => window.open(getDirectionsLink, '_blank')}
          primaryActionColor="primary--sbg"
        /> */}

        <CustomBlock className="custom-content-section" style={{ position: 'relative', zIndex: '2' }}>
          {/* <h1><span className="custom-header--primary-light">Transport</span> Logics</h1> */}
          {/* <h1 className="custom-header--primary-light">Transport Logics</h1> */}
          {/* <br /> */}

          <h2 className="fw-800">Location</h2>
          <h3 className="primary--clr mt-10">Physical Address</h3>
          <p>{cmsInfo.streetAddress1}, {cmsInfo.streetAddress2}</p>
          <p>{cmsInfo.city}, {cmsInfo.state}, {cmsInfo.zipCode}</p>
          {/* <h3 className="primary--clr mt-10">Mailing Address</h3> */}
          {/* <p>P.O. Box 448</p> */}
          <br />

          <h2 className="fw-800">Contact</h2>
          <p>Email: {cmsInfo.emailAddress}</p>
          <p>Phone: {cmsInfo.phoneNumber}</p>
          {/* <p>Fax: (225) 685-1044</p> */}
          <br />
          {(cmsInfo.primaryWeekDayHours || cmsInfo.secondaryWeekDayHours || cmsInfo.primaryWeekEndHours || cmsInfo.secondaryWeekEndHours) && (
            <>
              <h2>Hours of Operation</h2>
              {(cmsInfo.primaryWeekDayHours || cmsInfo.secondaryWeekDayHours) && (
                <CustomBlock className="mt-5 mb-5">
                  <h3 className="primary--clr">Weekdays</h3>

                  {cmsInfo.primaryWeekDayHours && (
                    <p>{cmsInfo.primaryWeekDayHours}</p>
                  )}
                  {cmsInfo.secondaryWeekDayHours && (
                    <p>{cmsInfo.secondaryWeekDayHours}</p>
                  )}
                </CustomBlock>
              )}
              {(cmsInfo.primaryWeekEndHours || cmsInfo.secondaryWeekEndHours) && (
                <CustomBlock>
                  <h3 className="primary--clr">Weekends</h3>
                  {cmsInfo.primaryWeekEndHours && (
                    <p>{cmsInfo.primaryWeekEndHours}</p>
                  )}
                  {cmsInfo.secondaryWeekEndHours && (
                    <p>{cmsInfo.secondaryWeekEndHours}</p>
                  )}
                </CustomBlock>
              )}
            </>
          )}
        </CustomBlock>

        <CustomBlock className="content-section full-width">
          <Image
            containerClassName="background-decoration-container"
            className="background-decoration"
            source={BackgroundDecorationImage}
          />
        </CustomBlock>

        <CustomBlock className="content-section" style={{ position: 'relative', zIndex: '2' }}>
          <CustomBlock className="content-container--card-style--with-shadow">
            <ContactForm
              formConfig={formConfig}
              onSubmit={(values) => submitForm(values)}
              title={<p className="secondary-lighter--clr"><span className="custom-header--primary-light primary--clr fw-800">Questions?</span> We Have Answers</p>}
              className="mb-20"
            />
          </CustomBlock>
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...auth.actions,
  ...alertToast.actions
})(ContactPage);