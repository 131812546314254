/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
// DEPENDENCIES
import React, { useState, useContext } from 'react';
import { connect } from 'react-redux';
// ICONS
import { FaUserCheck } from 'react-icons/fa';
import { FaRegCircleCheck } from 'react-icons/fa6';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import ContentHeader from './ContentHeader';
import Image from './Image';
import ModalBlock from './ModalBlock';
import Button from './Button';
// FORMS
import PayTraceAuthForm from '../pages/cms/forms/PayTraceAuthForm';
import PayTracePaymentForm from '../pages/cms/forms/PayTracePaymentForm';
// ASSETS
import PayTraceLogo from '../assets/img/logo_pay-trace.svg';
// REDUX
import * as auth from '../redux/authRedux';
import * as alertToast from '../redux/alertToastRedux';
// CONTEXT
import PayTraceModalContext from '../context/PayTraceModalContext';

const PayTraceForm = () => {
  const { isPayTraceModalOpen, setIsPayTraceModalOpen } = useContext(PayTraceModalContext);

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [authResponse, setAuthResponse] = useState('');
  const [paymentResponse, setPaymentResponse] = useState('');

  const handleModalClose = () => {
    setAuthResponse('');
    setPaymentResponse('');
    setIsPayTraceModalOpen(false);
  };

  return (
    <ModalBlock
      hasCloseAction
      isCentered={false}
      isVisible={isPayTraceModalOpen}
      size="md"
      // contentHeader=""
      // contentHeader=""
      primaryModalActionText=""
      primaryModalActionOnClick=""
      primaryModalActionColor=""
      secondaryModalActionText=""
      secondaryModalActionOnClick=""
      secondaryModalActionColor=""
      onHide={handleModalClose}
      className="paytrace-modal"
    >
      {/* MODAL HEADER */}
      <ContentHeader
        title={authResponse ? 'Secure Payment' : 'PayTrace Login'}
        subtitle={authResponse ? 'Complete the fields below to finalize your secure transaction.' : 'Sign in to make secure payments with your PayTrace account.'}
        headerSize="mdlg"
        className="dark-grey--clr pb-10"
        containerClassName="paytrace-modal__main-header"
      />

      {/* MODAL SUBHEADER */}
      {authResponse ? (
        <>
          <Image
            source={PayTraceLogo}
            containerClassName="paytrace-modal__image-container mt-30 mb-20"
          />
          <CustomBlock className="paytrace-modal__username-container mb-60">
            <FaUserCheck size="24" className="success--clr" />
            <p className="p-0 size-sm success--clr fw-500">{authResponse.username}</p>
          </CustomBlock>
        </>
      ) : (
        <Image
          source={PayTraceLogo}
          containerClassName="paytrace-modal__image-container mt-30 mb-60"
        />
      )}

      {!authResponse ? (
        // AUTH FORM
        <PayTraceAuthForm
          setAuthResponse={setAuthResponse}
        />
      ) : !paymentResponse ? (
        // PAYMENT FORM
        <PayTracePaymentForm
          authResponse={authResponse}
          setPaymentResponse={setPaymentResponse}
          handleModalClose={handleModalClose}
        />
      ) : (
        // PAYMENT RESPONSE
        <>
          <CustomBlock className="paytrace-modal__payment-response">
            <CustomBlock className="header success--sbg">
              <FaRegCircleCheck
                size="42"
                className="white--clr"
                style={{ marginInline: 'auto' }}
              />
            </CustomBlock>

            <CustomBlock className="content">
              <p className="size-sm mid-grey--clr mb-20">{paymentResponse.message}</p>
              <p className="transaction-id size-sm mid-grey--clr">
                Transaction ID:
                <span className="fw-600">{paymentResponse.transactionId}</span>
              </p>
              {/* <p className="response-code size-sm mid-grey--clr">{paymentResponse.responseCode}</p>
              <p className="mid-grey--clr">{paymentResponse.approvalCode}</p>
              <p className="size-xs mid-grey--clr">{paymentResponse.approvalMessage}</p> */}
            </CustomBlock>
          </CustomBlock>

          <CustomBlock className="button-container--flex-end mt-30">
            <Button
              type="button"
              text="Close"
              className="primary--bg"
              onClick={handleModalClose}
            />
          </CustomBlock>
        </>
      )}

      {/* PAY TRACE SANDBOX CREDIT CARD TESTING */}
      {/* <CustomBlock className="mt-20">
        <p className="mid-grey--clr" style={{ display: 'flex', gap: '5px', fontSize: '12px' }}>VISA: 4012000098765439<span style={{ display: 'block' }}>999</span></p>
        <p className="mid-grey--clr" style={{ display: 'flex', gap: '5px', fontSize: '12px' }}>MASTER CARD: 5499740000000057<span style={{ display: 'block' }}>998</span></p>
        <p className="mid-grey--clr" style={{ display: 'flex', gap: '5px', fontSize: '12px' }}>DISCOVER: 6011000993026909<span style={{ display: 'block' }}>996</span></p>
        <p className="mid-grey--clr" style={{ display: 'flex', gap: '5px', fontSize: '12px' }}>AMEX: 371449635392376<span style={{ display: 'block' }}>9997</span></p>
      </CustomBlock> */}
    </ModalBlock>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...auth.actions, ...alertToast.actions })(PayTraceForm);