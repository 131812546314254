/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import Banner from '../../../components/Banner';
import Overlay from '../../../components/Overlay';
import ContentSection from '../../../components/ContentSection';
import MemberListing from '../../../components/MemberListing';
// ASSETS - AGENTS LISTING PAGE
import BannerImage from '../../../assets/img/banner-image_default.png';
import MemberImagePlaceholder from '../../../assets/img/member-image-placeholder.png';

const MemberListingPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [agents, setAgents] = useState([]);

  useEffect(() => {
    getMembers();
  }, []);

  const getMembers = () => {
    setIsLoading(true);
    const membersData = [
      {
        name: 'Aubrey Jayroe',
        emailAddress: 'aubrey@jayroeco.com',
        phoneNumber: '870-633-6045 ext 1001',
        title: 'Accredited Tax Advisor, Accredited Tax Preparer, Licensed to Practice before the IRS, Certified Tax Return Preparer',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/TeamMembers/aubrey-jayroe.png`,
      },
      {
        name: 'Trent Jayroe',
        emailAddress: '',
        phoneNumber: '',
        title: 'Registered Tax Preparer, Organization Manager',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/TeamMembers/trent-jayroe.png`,
      },
      {
        name: 'Robin Sanders',
        emailAddress: '',
        phoneNumber: '',
        title: 'Tax assistant',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/TeamMembers/robin-sanders.png`,
      },
      {
        name: 'Melody West',
        emailAddress: '',
        phoneNumber: '',
        title: 'Office Clerk',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/TeamMembers/melody-west.png`,
      },
      // {
      //   name: 'Jana Calvert',
      //   emailAddress: '',
      //   phoneNumber: '',
      //   title: 'Payroll Services Manager',
      //   link: '',
      //   linkTitle: '',
      //   image: `${process.env.REACT_APP_API_URL}Attachments/TeamMembers/jana-calvert.png`,
      // },
      // {
      //   name: 'Jennifer Sanders',
      //   emailAddress: '',
      //   phoneNumber: '',
      //   title: 'Bookkeeping Manager, Certified in Quickbooks',
      //   link: '',
      //   linkTitle: '',
      //   image: `${process.env.REACT_APP_API_URL}Attachments/TeamMembers/jennifer-sanders.png`,
      // },
      // {
      //   name: 'Jeanette Nichols',
      //   emailAddress: '',
      //   phoneNumber: '',
      //   title: 'Office Secretary',
      //   link: '',
      //   linkTitle: '',
      //   image: `${process.env.REACT_APP_API_URL}Attachments/TeamMembers/jeanette-nichols.png`,
      // },
      // {
      //   name: 'Misty Davis',
      //   emailAddress: '',
      //   phoneNumber: '',
      //   title: 'Payroll Specialist',
      //   link: '',
      //   linkTitle: '',
      //   image: `${process.env.REACT_APP_API_URL}Attachments/TeamMembers/misty-davis.png`,
      // }
    ];

    setAgents(membersData);
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isDynamicBanner
        isTitleUppercase
        title="Our Members"
        imageSource={BannerImage}
      />

      {/* <CustomBlock className="content-container--padded"> */}
      <CustomBlock className="">
        <ContentSection
          isTextOnly
          isFullWidthUnpadded
          title="Guiding You to Success"
          description="Discover the dedicated professionals at Jayroe & Company, each bringing unique expertise to help you achieve your financial goals with confidence and ease."
        />
        <CustomBlock className="content-container">
          <MemberListing
            members={agents}
            defaultImage={MemberImagePlaceholder}
          />
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

export default MemberListingPage;