/* eslint-disable max-len */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-param-reassign */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
// COMPONENTS
import Slider from 'react-slick';
import { Parallax } from 'react-scroll-parallax';
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import Overlay from '../../../components/Overlay';
import Banner from '../../../components/Banner';
// import AnimatedLogo from '../../../components/AnimatedLogo';
import Image from '../../../components/Image';
import Button from '../../../components/Button';
// import ContentStrip from '../../../components/ContentStrip';
// ASSETS
import BannerImage from '../../../assets/img/homepage-banner-image_default.png';
import HomepageServiceTax from '../../../assets/img/home-services_tax.png';
import HomepageServicesBusiness from '../../../assets/img/home-services_business.png';
import HomepageServicesFinancial from '../../../assets/img/home-services_financial.png';
// HELPERS
import constants from '../../../constants/constants';
// SERVICES

const HomePage = (props) => {
  // const { dynamicPageModel, buttonAction, cmsInfo, history } = props;
  const { dynamicPageModel, buttonAction, history } = props;
  const [isLoading] = useState(false);
  const [banners, setBanners] = useState([]);
  const [offset, setOffset] = useState(0);
  const [progress, setProgress] = useState(0);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const servicesOverview = [
    {
      title: 'Tax & Compliance',
      illustration: HomepageServiceTax,
      description: 'Craft a solid tax plan with expert guidance to minimize liability and enhance savings. Strong IRS advocacy ensures your interests are protected.',
    },
    {
      title: 'Business Advisory',
      illustration: HomepageServicesBusiness,
      description: 'Streamline your business structure for peak performance. Accurate financial forecasting prepares you for what lies ahead.',
    },
    {
      title: 'Financial Planning',
      illustration: HomepageServicesFinancial,
      description: 'Protect your assets and pass on your legacy with our thoughtful estate and trust planning. Custom retirement strategies for a secure future.',
    },
  ];

  useEffect(() => {
    const rotatingBanners = dynamicPageModel.pageContentSectionBanners.find((x) => x.bannerId > 0);
    if (rotatingBanners) {
      setBanners(rotatingBanners.banner.bannerItems);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => setOffset(window.pageYOffset);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const parallaxContainers = document.querySelectorAll('.parallax-container');

    const scrollHandler = () => {
      parallaxContainers.forEach((container) => {
        handleParallax(container);
      });
    };

    window.addEventListener('scroll', scrollHandler);

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  const handleParallax = (element, speed = 0.1) => {
    const yOffset = window.pageYOffset;
    const parallaxSpeed = yOffset * speed;
    element.style.backgroundPositionY = `calc(20% - ${parallaxSpeed}px)`;
  };

  const bannerSliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,

    responsive: [
      {
        breakpoint: 480,
        settings: { dots: false }
      }
    ]
  };

  // const navigateToLink = (link) => {
  //   window.open(link, '_blank');
  // };

  return (
    <>
      {(isLoading) && <Overlay hasLoader />}
      <CustomBlock>
        {/* BANNER SECTION */}
        <CustomBlock className="banner-container">
          <Slider {...bannerSliderSettings}>
            {/* CUSTOM BANNER INSERT */}
            <CustomBlock className="custom-banner-container">
              <Banner
                className="custom-banner"
                isTitleUppercase
                title={(
                  <CustomBlock className="custom-banner-content mb-15">
                    <div className="custom-banner-title-container mb-15">
                      {/* <p className="custom-banner-title text-effect-2 fw-500">Ship Smarter, <span className="fw-600">Grow Faster</span></p> */}
                      {/* <p className="custom-banner-title text-effect-2 fw-500 secondary--clr">Master <span className="fw-600 secondary-lighter--clr">Your</span> Money</p> */}
                      <p className="custom-banner-title secondary--clr text-effect-2 fw-700">Grow Your Wealth</p>
                      <span className="secondary-lighter--clr fw-700">Stress-Free</span>
                    </div>
                  </CustomBlock>
                )}
                imageSource={BannerImage}
                description={(
                  // <p className="custom-banner-description">Navigate your finances with confidence.<br />Expert tax and wealth strategies tailored to your life.</p>
                  // <p className="custom-banner-description">Smart strategies, brighter futures.<br />Personal finance and tax expertise at your fingertips.</p>
                  <p className="custom-banner-description primary-lighter--clr fw-400">Jayroe delivers personalized tax and financial strategies to <span className="fw-500">propel your business and personal wealth forward.</span></p>
                )}
                // primaryActionText="Start Saving Now"
                primaryActionText={(
                  <>
                    {/* <PiHandCoins size="25px" /> */}
                    {/* <GiTakeMyMoney className="size-lg" /> */}
                    <p className="size-sm">Find Your Tax Organizer</p>
                  </>
                )}
                primaryActionOnClick={() => {
                  history.push('/tax-organizer-documents');
                }}
                primaryActionColor="secondary--sbg white--clr size-mdlg"
                // secondaryActionText={banner.secondaryButtonText}
                // secondaryActionOnClick={() => {
                //   buttonAction(banner.secondaryButtonActionType, banner.secondaryButtonLink);
                // }}
                offset={offset}
              />
            </CustomBlock>
            {
              banners.map((banner) => (
                <Banner
                  isDynamicBanner
                  isTitleUppercase
                  isReversed={banner.alignment === constants.CONTENT_SECTION.RightAlignment}
                  isCentered={banner.alignment === constants.CONTENT_SECTION.CenterAlignment}
                  title={banner.title}
                  imageSource={`${process.env.REACT_APP_API_URL}Attachments${banner.imagePath}`}
                  description={banner.description}
                  primaryActionText={banner.primaryButtonText}
                  primaryActionOnClick={() => {
                    buttonAction(banner.primaryButtonActionType, banner.primaryButtonLink);
                  }}
                  secondaryActionText={banner.secondaryButtonText}
                  secondaryActionOnClick={() => {
                    buttonAction(banner.secondaryButtonActionType, banner.secondaryButtonLink);
                  }}
                  offset={offset}
                />
              ))
            }
          </Slider>
        </CustomBlock>

        {/* WEBSITE INTRO */}
        {/*
        <CustomBlock className="responsive-content-section">
          <CustomBlock className="website-intro pb-30">
            <CustomBlock className="svg-container mb-30">
              <AnimatedLogo
                onClick
                width="350px"
              />
            </CustomBlock>
            <p className="responsive-content-section__description description mid-grey--clr size-mdlg pb-30">Shipment Savings, Delivered!</p>
          </CustomBlock>
        </CustomBlock>
        */}

        {/* INTRO TO ABOUT */}
        <CustomBlock className="responsive-content-section homepage-about-intro mb-60">
          <CustomBlock className="background-wrapper" style={{ backgroundImage: 'linear-gradient(45deg, #408697 20%, #408697 100%), url(https://img.freepik.com/free-photo/white-gray-color-wood-texture-surface_74190-8287.jpg?w=1380&t=st=1704923114~exp=1704923714~hmac=2320b485554312ef51e33b31828d0ce9608766f5f13bd6fa20c124460e3f9406)' }} />
          <CustomBlock className="intro-to-about pt-80 pb-50">
            <p className="light--clr responsive-content-section__header pb-20">A New Era of Financial Management</p>
            <p className="white--clr responsive-content-section__description size-mdlg fw-300">At Jayroe & Company, we&apos;re more than just financial advisors; <span className="light--clr fw-600">we&apos;re architects of your financial well-being</span>.</p>
            <br />
            <p className="white--clr responsive-content-section__description size-mdlg fw-300 pb-30">Leveraging over four decades of expertise, <span className="light--clr fw-600">we craft personalized strategies that adapt to your financial aspirations and the ever-evolving economic landscape</span>. Our dedicated team ensures your financial planning is comprehensive, forward-thinking, and aligned with your life&apos;s goals.</p>
            <Button
              round-corners
              text="Begin Your Financial Journey"
              to="/jayroe-financial-planning"
              className="secondary--sbg mt-40"
            />
          </CustomBlock>
        </CustomBlock>

        {/* INTROS TO SERVICES */}
        <CustomBlock className="responsive-content-section">
          {/* <Image
            containerClassName="background-decoration-container"
            className="background-decoration"
            source={BackgroundDecorationImage}
          /> */}

          <CustomBlock className="mult-intro mt-90">
            <CustomBlock className="listing-block mt-90">
              {
                servicesOverview.map((item) => (
                  <CustomBlock
                    className="item"
                  // onClick={() => history.push('/transport-logics-services')}
                  >

                    <CustomBlock className="img-container">
                      <Parallax
                        className="parallax-container"
                        onProgressChange={(progressInput) => setProgress(progressInput)}
                        translateY={[0, -50]}
                        easing="easeInQuad"
                      >
                        <img src={item.illustration} alt={`${item.title} Illustration`} />
                      </Parallax>
                    </CustomBlock>

                    <CustomBlock className="content">
                      <p className="header size-mdlg secondary--clr fw-700 pb-10">{item.title}</p>
                      <p className="description mid-grey--clr size-sm">{item.description}</p>
                    </CustomBlock>
                  </CustomBlock>
                ))
              }
            </CustomBlock>

            <p className="responsive-content-section__header mt-90">Financial Expertise Tailored for You</p>
            <p className="responsive-content-section__description">straightforward financial solutions. Jayroe & Company cuts through the complexity, delivering the expert advice you need to thrive.</p>

            <Button
              text="View Services"
              className="primary-lighter--sbg mt-30"
              size="xs"
              onClick={() => history.push('/jayroe-services')}
            />
          </CustomBlock>
        </CustomBlock>

      </CustomBlock>
    </>
  );
};

export default HomePage;