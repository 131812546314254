/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
// DEPENDENCIES
import React, { useState, useContext } from 'react';
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import Banner from '../../../components/Banner';
import Overlay from '../../../components/Overlay';
import Button from '../../../components/Button';
import Image from '../../../components/Image';
// ASSETS
import BannerImage from '../../../assets/img/banner-image_default.png';
import PayTraceLogo from '../../../assets/img/logo_pay-trace.svg';
// CONTEXT
import PayTraceModalContext from '../../../context/PayTraceModalContext';

const PaymentsPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { isPayTraceModalOpen, setIsPayTraceModalOpen } = useContext(PayTraceModalContext);

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isCentered
        isDynamicBanner
        isTitleUppercase
        title="Payments"
        imageSource={BannerImage}
      />

      <CustomBlock className="main-content payments-page">

        {/* INTRO SECTION */}
        <CustomBlock className="content-section intro-section">
          <Image
            source={PayTraceLogo}
            className=""
            containerClassName="image-container"
          />

          <CustomBlock className="content">
            <div className="text-block-container">
              <div className="text-block left">
                <p className="header padded primary--clr">
                  Secure Online Payments
                </p>
              </div>
            </div>
            <div className="text-block-container">
              <div className="text-block left">
                <p className="description size-md padded dark--clr">
                  Jayroe & Company provides a secure and straightforward way for you to manage your financial transactions with us. Our payment portal, powered by Pay Trace, ensures your payment process is both safe and efficient.
                </p>
              </div>
            </div>
          </CustomBlock>

          <CustomBlock className="button-container--flex-start">
            <Button
              type="button"
              text="Make a Payment"
              size="sm"
              className="primary--bg"
              onClick={() => setIsPayTraceModalOpen(true)}
            />
          </CustomBlock>
        </CustomBlock>

      </CustomBlock>
    </>
  );
};

export default PaymentsPage;