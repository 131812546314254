import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'PayTrace/';

const authenticateUser = async (username, password) => axiosInstance.post(`${ENDPOINTORIGIN}AuthenticatePayTraceUser`, { username, password }, constants.REQUIRE_INTERCEPTORS);

const processPayment = async (paymentDetails, accessToken) => axiosInstance.post(`${ENDPOINTORIGIN}ProcessPayment`, { paymentDetails, accessToken }, constants.REQUIRE_INTERCEPTORS);

export {
  authenticateUser,
  processPayment
};