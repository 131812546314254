/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, {
  useEffect,
  useState
} from 'react';
import jwt_decode from 'jwt-decode';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { enableBodyScroll } from 'body-scroll-lock';
// ROUTES
import PrivateRoute from './routes/PrivateRoute';
import CMSRoute from './routes/CMSRoute';
// CUSTOM COMPONENTS
import ConfirmModal from './components/ConfirmModal';
import AlertToast from './components/AlertToast';
// AUTHENTICATION
import LoginPage from './pages/admin/authentication/LoginPage';
// MANAGEMENT PAGES - SYSTEM
import UserListingPage from './pages/admin/management/user/UserListingPage';
// PROJECT
// CMS
// -- MENU ITEMS
import MenuItemListingPage from './pages/cms/management/menu-item/MenuItemListingPage';
import MenuItemManagementPage from './pages/cms/management/menu-item/MenuItemManagementPage';
// -- CONTENT PAGES
import PageListingPage from './pages/cms/management/page/PageListingPage';
import PageManagementPage from './pages/cms/management/page/PageManagementPage';
// -- CONTENT SECTIONS
import ContentSectionListingPage from './pages/cms/management/content-section/ContentSectionListingPage';
import ContentSectionManagementPage from './pages/cms/management/content-section/ContentSectionManagementPage';
import BannerManagementPage from './pages/cms/management/content-section/BannerManagementPage';
// ASSETS AND RESOURCES
import AssetsAndResourcesPage from './pages/cms/management/resources/AssetsAndResourcesPage';
// FOOTER ITEMS
import FooterItemListingPage from './pages/cms/management/footer-items/FooterItemListingPage';
// EVENT LISTING PAGE
// CMS PAGES
import ContactPage from './pages/cms/pages/ContactPage';
import MemberListingPage from './pages/cms/pages/MemberListingPage';
import PaymentsPage from './pages/cms/pages/PaymentsPage';
// BLOGS
import BlogListingPage from './pages/cms/management/blog/BlogListingPage';
import BlogManagementPage from './pages/cms/management/blog/BlogManagementPage';
import BlogsAndReviewsListingPage from './pages/cms/pages/BlogsAndReviewsListingPage';
import DynamicCMSPage from './pages/cms/pages/DynamicCMSPage';
import Overlay from './components/Overlay';
// SERVICES
import * as homeService from './services/cms/homeService';
// import ClientManagementPage from './pages/admin/management/client/ClientManagementPage';
// import ClientListingPage from './pages/admin/management/client/ClientListingPage';

const initialCMSInfo = {
  appStoreLink: '',
  city: '',
  companyIconPath: '',
  companyLogoPath: '',
  companyName: '',
  emailAddress: '',
  facebookLink: '',
  googleMapLink: '',
  googlePlayStoreLink: '',
  instagramLink: '',
  linkedInLink: '',
  menuItems: [],
  pages: [],
  footerItems: [],
  phoneNumber: '',
  primaryWeekDayHours: '',
  primaryWeekEndHours: '',
  secondaryWeekDayHours: '',
  secondaryWeekEndHours: '',
  state: '',
  streetAddress1: '',
  streetAddress2: '',
  twitterLink: '',
  youtubeLink: '',
  zipCode: '',
};

const Routes = (props) => {
  const { auth } = props;
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [cmsInfo, setCmsInfo] = useState(initialCMSInfo);
  const { pathname } = useLocation();

  useEffect(() => {
    fetchCMSInfoAndValidate();
  }, [auth]);

  const fetchCMSInfoAndValidate = async () => {
    setIsLoading(true);
    validateAccess();

    await homeService.getCMSInfo().then((res) => {
      setCmsInfo(res);
    }).catch().finally(() => {
      setIsLoading(false);
    });
  };

  const validateAccess = () => {
    if (auth.authToken !== '') {
      try {
        let decodedToken = jwt_decode(auth.authToken);
        setIsAuthorized(!!decodedToken);
      } catch (ex) {
        setIsAuthorized(false);
      }
    } else {
      setIsAuthorized(false);
    }
  };

  useEffect(() => {
    const body = document.querySelector('body');
    window.scrollTo(0, 0);
    enableBodyScroll(body);
  }, [pathname]);

  return (
    <>
      {
        cmsInfo.googleAnalyticsId && (
          <Helmet>
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${cmsInfo.googleAnalyticsId}`} />
            <script>
              {`window.dataLayer = window.dataLayer || [];
                  function gtag() { dataLayer.push(arguments); }
                  gtag('js', new Date());
                  gtag('config', '${cmsInfo.googleAnalyticsId}');`}
            </script>
          </Helmet>
        )
      }
      <AlertToast />
      <ConfirmModal />
      <>
        {
          isLoading ? <Overlay hasLoader />
            : (
              <Switch>
                <Redirect
                  exact
                  from="/"
                  to="/home"
                />
                <Route
                  path="/login"
                  component={LoginPage}
                />

                {/* CMS PAGES */}
                {/* DYNAMIC CMS ROUTING */}
                {
                  cmsInfo.pages.map((page) => (
                    <CMSRoute
                      path={page.url}
                      component={DynamicCMSPage}
                      title={page.title}
                      dynamicPageId={page.id}
                      isBlog={page.isBlog}
                      cmsInfo={cmsInfo}
                    />
                  ))
                }

                <CMSRoute
                  path="/blogs-and-reviews"
                  component={BlogsAndReviewsListingPage}
                  title="Blogs and Reviews"
                  cmsInfo={cmsInfo}
                />

                {/* CONTACT PAGE */}
                <CMSRoute
                  path="/contact-jayroe"
                  component={ContactPage}
                  title="Contact Us"
                  cmsInfo={cmsInfo}
                />

                {/* MEMBER LISTING PAGE */}
                <CMSRoute
                  exact
                  path="/jayroe-team"
                  component={MemberListingPage}
                  title="Meet the Team"
                  cmsInfo={cmsInfo}
                />

                {/* PAYMENTS PAGE */}
                <CMSRoute
                  exact
                  path="/payments"
                  component={PaymentsPage}
                  title="Payment Portal"
                  cmsInfo={cmsInfo}
                />

                {
                  isAuthorized
                    ? (
                      <>
                        {/* USERS */}
                        <PrivateRoute
                          path="/admin/management/users"
                          component={UserListingPage}
                          title="Manage Users"
                          cmsInfo={cmsInfo}
                        />

                        {/* <PrivateRoute
                          path="/admin/management/clients"
                          component={ClientListingPage}
                          title="Manage Clients"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          path="/admin/management/client/:clientId"
                          component={ClientManagementPage}
                          title="Manage Client"
                          cmsInfo={cmsInfo}
                        /> */}

                        {/* <PrivateRoute
                          path="/admin/management/bids"
                          component={BidListingPage}
                          title="Manage Client"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          path="/admin/management/bid/:bidId"
                          component={BidManagementPage}
                          title="Manage Bid"
                          cmsInfo={cmsInfo}
                        /> */}
                        {/* CMS */}

                        {/* MENU ITEMS */}
                        <PrivateRoute
                          path="/cms/management/menu-items"
                          component={MenuItemListingPage}
                          title="Manage Menu Items"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/menu-item/edit/:menuItemId"
                          component={MenuItemManagementPage}
                          title="Edit Menu Item"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/menu-item"
                          component={MenuItemManagementPage}
                          title="Manage Menu Item"
                          cmsInfo={cmsInfo}
                        />

                        {/* PAGES */}
                        <PrivateRoute
                          path="/cms/management/pages"
                          component={PageListingPage}
                          title="Manage Pages"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/page"
                          component={PageManagementPage}
                          title="Manage Page"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/page/edit/:pageId"
                          component={PageManagementPage}
                          title="Manage Page"
                          cmsInfo={cmsInfo}
                        />

                        {/* BLOGS */}
                        <PrivateRoute
                          path="/cms/management/blogs"
                          component={BlogListingPage}
                          title="Manage Blogs"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/blog"
                          component={BlogManagementPage}
                          title="Manage Page"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/blog/edit/:blogId"
                          component={BlogManagementPage}
                          title="Manage Blog"
                          cmsInfo={cmsInfo}
                        />

                        {/* CONTENT SECTIONS */}
                        <PrivateRoute
                          exact
                          path="/cms/management/content-sections"
                          component={ContentSectionListingPage}
                          title="Manage Content Sections"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/content-section"
                          component={ContentSectionManagementPage}
                          title="Manage Content Section"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/content-section/pageId/:pageId"
                          component={ContentSectionManagementPage}
                          title="Manage Content Section"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/content-section/blogId/:blogId"
                          component={ContentSectionManagementPage}
                          title="Manage Content Section"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/content-section/edit/:contentSectionId"
                          component={ContentSectionManagementPage}
                          title="Manage Content Section"
                          cmsInfo={cmsInfo}
                        />

                        {/* BANNER */}
                        <PrivateRoute
                          exact
                          path="/cms/management/banner"
                          component={BannerManagementPage}
                          title="Banner Management"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/banner/pageId/:pageId"
                          component={BannerManagementPage}
                          title="Banner Management"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/banner/blogId/:blogId"
                          component={BannerManagementPage}
                          title="Banner Management"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/banner/edit/:bannerId"
                          component={BannerManagementPage}
                          title="Banner Management"
                          cmsInfo={cmsInfo}
                        />

                        {/* ASSETS AND RESOURCES */}
                        <PrivateRoute
                          path="/cms/management/assets-and-resources"
                          component={AssetsAndResourcesPage}
                          title="Manage Assets, Links and Resources"
                          cmsInfo={cmsInfo}
                        />

                        {/* FOOTER ITEMS */}
                        <PrivateRoute
                          path="/cms/management/footer-items"
                          component={FooterItemListingPage}
                          title="Footer Items"
                          cmsInfo={cmsInfo}
                        />

                        {/* EVENTS */}
                        {/* <PrivateRoute
                          exact
                          path="/cms/management/events"
                          component={EventListingPage}
                          title="Manage Events"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/create-event"
                          component={EventManagementPage}
                          title="Manage Event"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/event/:eventId"
                          component={EventManagementPage}
                          title="Manage Event"
                          cmsInfo={cmsInfo}
                        /> */}
                      </>
                    )
                    : <Redirect to="/login" />
                }
              </Switch>
            )
        }
      </>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, null)(Routes);