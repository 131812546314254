/* eslint-disable react/self-closing-comp */
/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable camelcase */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import InputBlock from '../../../components/InputBlock';
import Button from '../../../components/Button';
import ContentBlock from '../../../components/ContentBlock';
// SERVICES & HELPERS
import * as helper from '../../../helpers/helper';
import * as payTraceService from '../../../services/cms/payTraceService';
import constants from '../../../constants/constants';
// REDUX
import * as alert from '../../../redux/alertToastRedux';
import * as authRedux from '../../../redux/authRedux';

const protectJSInstanceStyles = {
  cc: {
    // CC NUMBER
    width: '290px',
    height: '45px',
    padding_right: '0px',
    padding_bottom: '20px',
    padding_left: '0px',
    font_size: '16px',
    font_color: '#333',
    border_color: '#CCCCCCCC',
    border_style: 'solid',
    background_color: '#FFFFFF',
    // LABEL
    label_margin: '0 0 10px 0',
    label_color: '#333',
    label_font_weight: '600',
    // INPUT
    input_padding: '0 0 0 10px',
    input_border_radius: '7px',
    input_border_width: '1px',
    input_font_weight: '400',
  },
  exp: {
    // EXPIRATION
    width: '100px',
    height: '45px',
    padding_bottom: '20px',
    font_size: '16px',
    font_color: '#333',
    border_color: '#CCCCCCCC',
    border_style: 'solid',
    background_color: '#FFFFFF',
    // LABEL
    label_margin: '0 0 10px 0',
    label_color: '#333',
    label_font_weight: '600',
    // INPUT
    input_border_radius: '7px',
    input_border_width: '1px',
    input_padding: '0 0 0 5px',
  },
  code: {
    // SECURITY CODE
    width: '195px',
    height: '45px',
    padding_bottom: '20px',
    font_size: '16px',
    font_color: '#333',
    border_color: '#CCCCCCCC',
    border_style: 'solid',
    background_color: '#FFFFFF',
    // LABEL
    label_margin: '0 0 10px 0',
    label_color: '#333',
    label_font_weight: '600',
    // INPUT
    input_padding: '0 10px',
    input_border_radius: '7px',
    input_border_width: '1px',
  },
};

const PayTracePaymentForm = (props) => {
  const { showAlert, authResponse, setPaymentResponse, handleModalClose } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [payTraceInstance, setPayTraceInstance] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState('');

  // PROTECT.JS INSTANCE (PAYTRACE HOSTED IFRAME PAYMENT FIELDS)
  const createProtectJSInstance = () => {
    const protectURL = 'https://protect.paytrace.com/js/protect.min.js';
    const protectSandboxURL = 'https://protect.sandbox.paytrace.com/js/protect.min.js';

    const script = document.createElement('script');
    script.src = authResponse.username === 'zane@primtek.net' ? protectSandboxURL : protectURL;
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.PTPayment) {
        window.PTPayment.setup({
          authorization: { clientKey: authResponse.clientKey },
          styles: protectJSInstanceStyles,
        }).then((instance) => {
          setPayTraceInstance(instance);
        }).catch(() => {
          // console.error('Error setting up Protect.js:', error);
        });
      }
    };

    script.onerror = () => {
      // console.error('Error loading Protect.js script:', error);
    };
  };

  useEffect(() => {
    if (authResponse) {
      createProtectJSInstance();
    }
  }, [authResponse]);

  const handlePaymentSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    if (!paymentAmount) {
      showAlert({
        text: 'Payment Amount is required.',
        state: 'error'
      });

      setIsLoading(false);
      return;
    }

    if (!payTraceInstance) {
      showAlert({
        text: 'Oops! Please make sure all required fields are filled in correctly.',
        state: 'error',
      });
      return;
    }

    try {
      const tokenizationResponse = await payTraceInstance.process();

      if (tokenizationResponse.success) {
        const paymentData = {
          amount: paymentAmount,
          hpf_token: tokenizationResponse.message.hpf_token,
          enc_key: tokenizationResponse.message.enc_key,
          integrator_id: constants.REACT_API_INTEGRATOR_ID,
        };

        const response = await payTraceService.processPayment(paymentData, authResponse.accessToken);

        const formattedResponse = {
          message: response.status_Message,
          responseCode: response.response_Code,
          approvalCode: response.approval_Code,
          approvalMessage: response.approval_Message,
          transactionId: response.transaction_Id,
        };

        setPaymentResponse(formattedResponse);
      } else {
        // console.log('error');
      }
    } catch (error) {
      showAlert({
        text: 'Oops! Please make sure all required fields are filled in correctly.',
        state: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaymentAmount = (input) => {
    const value = helper.formatAmount(input);
    setPaymentAmount(value);
  };

  return (
    <>
      <form id="ProtectForm" onSubmit={handlePaymentSubmit}>
        <ContentBlock>
          <InputBlock
            isCurrency
            id="amount"
            label="Payment Amount"
            placeholder="$ 00.00"
            backgroundColor="white--sbg"
            onChange={(e) => handlePaymentAmount(e.target.value)}
          />
        </ContentBlock>

        <hr style={{ height: '2px', color: '#9d9d9dCC', width: '80%', margin: '20px auto 30px auto' }} />

        {/* PROTECT JS FORM */}
        <ContentBlock>
          <div id="pt_hpf_form"></div>
        </ContentBlock>
      </form>

      {/* PAYMENT FORM ACTIONS */}
      <CustomBlock className="button-container--flex-end mt-30">
        <Button
          text="Cancel"
          className="danger--bg mr-10"
          onClick={handleModalClose}
        />
        <Button
          isLoading={isLoading}
          type="submit"
          text="Submit Payment"
          className="secondary--sbg light--clr"
          onClick={handlePaymentSubmit}
        />
      </CustomBlock>

      <CustomBlock className="mt-60" style={{ display: 'flex', justifyContent: 'center', opacity: '0.90' }}>
        <img
          src="https://b2747218.smushcdn.com/2747218/wp-content/uploads/2022/11/visa-mastercard-discover-american-express-icons-2.png?lossy=0&strip=1&webp=1"
          alt=""
          style={{ width: '70%', margin: '0 auto' }}
        />
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...authRedux.actions,
    ...alert.actions
  }
)(PayTracePaymentForm);