// DEPENDENCIES
import React, { useState } from 'react';
import { useFormik } from 'formik';
// ICONS
import { FiLock, FiUser } from 'react-icons/fi';
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import InputBlock from '../../../components/InputBlock';
import Button from '../../../components/Button';
import FormBlock from '../../../components/FormBlock';
// SERVICES & HELPERS
import * as payTraceService from '../../../services/cms/payTraceService';

const PayTraceAuthForm = (props) => {
  const { setAuthResponse } = props;
  const [passwordVisible, setPasswordVisible] = useState(false);

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        const res = await payTraceService.authenticateUser(values.username, values.password);
        if (res) {
          setAuthResponse(res);
          // console.log('AUTH RESPONSE', res);
        }
      } catch (error) {
        // console.error('Error', error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <FormBlock onSubmit={formik.handleSubmit}>
        {/* USERNAME */}
        <InputBlock
          id="username"
          label="Username"
          placeholder="Enter username"
          type="text"
          name="username"
          onChange={formik.handleChange}
          value={formik.values.username}
          iconLeft={<FiUser size="22" className="dark-grey--clr" />}
        />

        {/* PASSWORD */}
        <InputBlock
          id="password"
          label="Password"
          placeholder="Enter password"
          type={passwordVisible ? 'text' : 'password'}
          name="password"
          onChange={formik.handleChange}
          value={formik.values.password}
          iconLeft={<FiLock size="22" className="dark-grey--clr" />}
          inputRightButtonText={passwordVisible ? 'Hide' : 'Show'}
          inputRightButtonOnClick={() => setPasswordVisible(!passwordVisible)}
        />
      </FormBlock>

      {/* AUTH FORM ACTIONS */}
      <CustomBlock className="button-container--flex-end">
        <Button
          type="submit"
          text="Sign In"
          className="primary--bg"
          isLoading={formik.isSubmitting}
          isDisabled={formik.isSubmitting || !formik.isValid}
          onClick={formik.handleSubmit}
        />
      </CustomBlock>
    </>
  );
};

export default PayTraceAuthForm;