// DEPENDENCIES
import React, { useState } from 'react';
import PayTraceModalContext from './PayTraceModalContext';

const PayTraceModalProvider = ({ children }) => {
  const [modalState, setModalState] = useState({
    isPayTraceModalOpen: false,
    setIsPayTraceModalOpen: (isOpen) => {
      setModalState((prevState) => ({
        ...prevState,
        isPayTraceModalOpen: isOpen,
      }));
    },
  });

  return (
    <PayTraceModalContext.Provider value={modalState}>
      {children}
    </PayTraceModalContext.Provider>
  );
};

export default PayTraceModalProvider;