const constants = {
  API_URL: process.env.REACT_APP_API_URL,
  REQUIRE_INTERCEPTORS: {
    requestHandlerEnabled: true,
    responseHandlerEnabled: true
  },
  CONTENT_SECTION: {
    ContentSectionTypeDefault: 'CONTENT_SECTION_DEFAULT',
    ContentSectionTypeReversed: 'CONTENT_SECTION_REVERSED',
    ContentSectionTypeFullWidth: 'CONTENT_SECTION_FULLWIDTH',
    ContentSectionTypeFullWidthPadded: 'CONTENT_SECTION_FULLWIDTH_PADDED',
    ContentSectionTypeTextOnly: 'CONTENT_SECTION_TEXTONLY',
    ContentSectionTypeCard: 'CONTENT_SECTION_CARD',
    ContentSectionTypeBanner: 'CONTENT_SECTION_BANNER',
    ContentSectionDynamicBanner: 'CONTENT_SECTION_DYNAMIC_BANNER',
    ContentSectionCustom: 'CONTENT_SECTION_CUSTOM',
    LeftAlignment: 'LEFT',
    RightAlignment: 'RIGHT',
    CenterAlignment: 'CENTER',
    ButtonActionTypePage: 'PAGE',
    ButtonActionTypeLink: 'LINK_NEW_TAB',
    ButtonActionTypePhoneNumber: 'PHONENUMBER',
    ButtonActionTypeEmail: 'EMAIL',
  },
  EXCEL_FILE_TYPES: [
    'application/vnd.ms-excel',
    'application/msexcel',
    'application/x-msexcel',
    'application/x-ms-excel',
    'application/x-excel',
    'application/x-dos_ms_excel',
    'application/xls',
    'application/x-xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv'
  ],
  // JAYROE & COMPANY - PAYTRACE INTEGRATION
  REACT_API_INTEGRATOR_ID: '99567Primtek',
};

export default constants;